import type { EditorJsonContent, FalconContent, TilesContent, VideoSrc } from '../contentTypes';
import { tileModelToNemo } from './tileModelToNemo';

export const mapHeadlessContent = (content: FalconContent): TilesContent => {
  const { layout_name: name, layout_size: size, tiles } = content.fields;
  const appContent: TilesContent = {
    layout: {
      name,
      size,
    },
    tiles: tiles.map((tile) => tileModelToNemo(tile)),
  };
  return appContent;
};

export const mapFaceContent = (content: EditorJsonContent): TilesContent => {
  const { layout, tiles } = content;
  const newTiles = tiles.map((tile) => {
    const { media, logo, ...restTile } = tile;
    const { src: logoSrc, srcAsset: logoSrcAsset, ...restLogo } = logo;
    const { image, video, ...restMedia } = media;
    const { srcSet, ...restImage } = image;
    const { srcs, ...restVideo } = video;
    const newSrcSet = srcSet.map((srcItem) => {
      const newSrc =
        typeof srcItem.srcAsset === 'object' && srcItem.srcAsset?.assetValue
          ? srcItem.srcAsset?.assetValue
          : srcItem.src;
      return { bp: srcItem.bp, src: newSrc, srcAsset: '' };
    });
    const newVideoSrc = Object.entries(srcs).reduce((acc, [key, value]) => {
      const assetSrc = typeof value?.srcAsset === 'object' && value?.srcAsset?.assetValue;
      const newSrc = assetSrc || value?.src;
      const assetPoster = typeof value?.posterAsset === 'object' && value?.posterAsset?.assetValue;
      const newPoster = assetPoster || value?.poster;
      return {
        ...acc,
        [key]: {
          src: newSrc,
          srcAsset: '',
          poster: newPoster,
          posterAsset: '',
        },
      };
    }, {}) as VideoSrc;
    return {
      logo: {
        src: (typeof logoSrcAsset === 'object' && logoSrcAsset.assetValue) || logoSrc || '',
        srcAsset: { assetValue: '' },
        ...restLogo,
      },
      media: {
        image: {
          srcSet: newSrcSet,
          ...restImage,
        },
        video: {
          srcs: newVideoSrc,
          ...restVideo,
        },
        ...restMedia,
      },
      ...restTile,
    };
  });

  return { layout, tiles: newTiles };
};
